import React from "react"
import { Link } from "gatsby"
import "../styles/global.css"
import "../styles/footer.css"

export default function Footer() {
  return (
    <footer>
      <span className="title-footer">GROUNDED</span>

      <div className="body-container">
        <a
          className="insta-link"
          href="https://www.instagram.com/grounded.archive/"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-instagram"
          >
            <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
            <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
          </svg>
        </a>
        <div className="site-navigation">
          <Link to="/gallery" className="">
            The Archive
          </Link>
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
        </div>
      </div>
    </footer>
  )
}
